import * as React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { Meta } from "../../components/Meta";
import { theme } from "../../components/theme";
import { CONTACT_FORM_URL } from "../../config/constans";
import { Helmet } from "react-helmet";

interface ContactProps {}

const Contact: React.FC<ContactProps> = ({}) => {
  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>{`mogFlowts - Contact`}</title>
      </Helmet>

      <Container>
        <iframe
          title={"Contact"}
          src={CONTACT_FORM_URL}
          width="100%"
          height="745"
          frameBorder={0}
          marginHeight={0}
          marginWidth={0}
        >
          読み込んでいます…
        </iframe>
      </Container>
    </Layout>
  );
};

export default Contact;

const Container = styled.div`
  margin: 0 auto 40px;

  width: 100vw;

  text-align: center;

  & > :nth-child(1) {
    margin: 0 auto;
  }

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 40px 15px 80px;
    width: ${theme.width.main};
  }
`;
